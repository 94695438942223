<template>
  <div class="container" v-if="$route.fullPath != '/theListedWebsites'">
    <div class="nav" ref="nav">
      <div class="logo">
        <img ref="img" :src="imgUrl" />
      </div>
      <div class="menu" ref="menu">
        <div
          class="menu-item"
          v-for="(item, index) in navList"
          :key="'menu-item' + index"
          :class="index == navIndex ? 'active' : ''"
          @click="navClick(index, item.name)"
        >
          <router-link :to="item.path" ref="aa" v-if="item.path">
            {{ item.name }}
            <span
              v-if="item.children.length > 0"
              class="glyphicon glyphicon-menu-down"
            ></span>
            <i class="underline"></i>
          </router-link>
          <div
            v-else
            style="cursor: pointer"
            @click="jumpOutlink"
            class="divStyle"
          >
            {{ item.name }}
            <span class="glyphicon glyphicon-menu-down"></span>
            <i class="underline"></i>
          </div>
        </div>
        <!-- <div>物流科技</div>
        <div>产品服务</div>
        <div>智能科技</div>
        <div>新闻资讯</div>
        <div>人才招聘</div>
        <div>上市网站</div>-->
      </div>
      <div class="lang">
        <!-- <div class="lang-item">
          <el-dropdown trigger="click">
            <div class="el-dropdown-link">
              中文
              <i class="el-icon-arrow-down el-icon--right"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>中文</el-dropdown-item>
              <el-dropdown-item>English</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div> -->
        <div class="phone">
          <div class="phonePic">
            <img :src="phoneIcon" alt="" />
          </div>
          <!-- <i class="el-icon-phone-outline"></i> -->
          <div class="phoneNum">0755-2536 9609</div>
        </div>
      </div>
    </div>
    <div v-if="$route.path == '/'">
      <el-carousel trigger="click" height="1080px" autoplay>
        <el-carousel-item
          v-for="(item, index) in swiperList"
          :key="item.path + index"
        >
          <img :src="item.img" width="100%" height="100%" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-if="$route.path == '/service'">
      <el-carousel
        trigger="click"
        height="890px"
        arrow="never"
        :autoplay="false"
        indicator-position="none"
      >
        <el-carousel-item
          v-for="(item, index) in serviceList"
          :key="item.path + index"
        >
          <img :src="item.img" width="100%" height="100%" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-if="$route.path == '/smartTechnology'">
      <el-carousel
        trigger="click"
        height="890px"
        arrow="never"
        :autoplay="false"
        indicator-position="none"
      >
        <el-carousel-item
          v-for="(item, index) in smartList"
          :key="item.path + index"
        >
          <img :src="item.img" width="100%" height="100%" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-if="$route.path == '/newsInformation'">
      <el-carousel
        trigger="click"
        height="890px"
        arrow="never"
        :autoplay="false"
        indicator-position="none"
      >
        <el-carousel-item
          v-for="(item, index) in newsList"
          :key="item.path + index"
        >
          <img :src="item.img" width="100%" height="100%" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-if="$route.path == '/personnelRecruitment'">
      <el-carousel
        trigger="click"
        height="890px"
        arrow="never"
        :autoplay="false"
        indicator-position="none"
      >
        <el-carousel-item
          v-for="(item, index) in rencaiList"
          :key="item.path + index"
        >
          <img :src="item.img" width="100%" height="100%" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-else-if="$route.path == '/software'">
      <el-carousel
        trigger="click"
        height="890px"
        arrow="never"
        :autoplay="false"
        indicator-position="none"
      >
        <el-carousel-item
          v-for="(item, index) in productList"
          :key="item.path + index"
        >
          <img :src="item.img" width="100%" height="100%" />
          <div class="banner">
            <div class="title">{{ item.title }}</div>
            <div class="content">{{ item.content }}</div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div
      v-if="
        $route.path == '/newDetail' || $route.path == '/cooperationConsultation'
      "
    >
      <!-- <div class="newDetail" style="height:60px">111</div> -->
    </div>
  </div>
</template>

<script>
import imgLogo2 from "../assets/img/佳裕达LOGO@2x.png";
import imgLogo from "../assets/img/LOGO.png";
import PhoneIcon from "@/assets/img/phone2.png";
import PhoneIcon2 from "@/assets/img/telphone.png";
export default {
  data() {
    return {
      creList: ["投资者关系", "Investors"],
      Responsibility: [
        {
          img: require("@/assets/home/shehuizeren.png"),
        },
      ],
      phoneIcon: PhoneIcon2,
      serviceList: [
        {
          img: require("@/assets/home/chanpin.png"),
        },
      ],
      productList: [
        {
          img: require("@/assets/home/jituan.png"),
          path: "",
          // title: "专业高效，诚信服务",
          // content: "Professional and efficient, honest service"
        },
        // {
        //   img: require("@/assets/img/banner/jituanbanner.png"),
        //   path: "",
        //   // title: "专业高效，诚信服务",
        //   // content: "Professional and efficient, honest service"
        // },
        // {
        //   img: require("@/assets/img/banner/jituanbanner.png"),
        //   path: "",
        //   // title: "专业高效，诚信服务",
        //   // content: "Professional and efficient, honest service"
        // }
      ],
      imgUrl: imgLogo,
      navIndex: sessionStorage.getItem("navIndex")
        ? sessionStorage.getItem("navIndex")
        : 0,
      swiperList: [
        // {
        //   img: require("@/assets/home/上市成功宣传图.jpg"),
        //   path: "",
        //   // title: "面向世界，服务全球",
        //   // content: "Face the world, serve the world"
        // },
        {
          img: require("@/assets/home/立足深圳，服务全球 2@2x.png"),
          path: "",
          // title: "面向世界，服务全球",
          // content: "Face the world, serve the world"
        },
        {
          img: require("@/assets/home/home2.png"),
          path: "",
          // title: "面向世界，服务全球",
          // content: "Face the world, serve the world"
        },
        {
          img: require("@/assets/home/home3.png"),
          path: "",
          // title: "面向世界，服务全球",
          // content: "Face the world, serve the world"
        },
        {
          img: require("@/assets/home/home4.png"),
          path: "",
          // title: "面向世界，服务全球",
          // content: "Face the world, serve the world"
        },
        {
          img: require("@/assets/home/home5.png"),
          path: "",
          // title: "面向世界，服务全球",
          // content: "Face the world, serve the world"
        },
        {
          img: require("@/assets/home/home6_2.png"),
          path: "",
          // title: "面向世界，服务全球",
          // content: "Face the world, serve the world"
        },
        {
          img: require("@/assets/home/home7.png"),
          path: "",
          // title: "面向世界，服务全球",
          // content: "Face the world, serve the world"
        },
      ],
      swiperList2: [
        {
          img: require("@/assets/img/homeBg.png"),
          path: "",
          title: "面向世界，服务全球",
          content: "Face the world, serve the world",
        },
        {
          img: require("@/assets/img/homeBg.png"),
          path: "",
          title: "面向世界，服务全球",
          content: "Face the world, serve the world",
        },
        {
          img: require("@/assets/img/homeBg.png"),
          path: "",
          title: "面向世界，服务全球",
          content: "Face the world, serve the world",
        },
      ],
      newsList: [
        {
          img: require("@/assets/home/news.png"),
          path: "",
          title: "新闻资讯",
          content: "News",
        },
        {
          img: require("@/assets/img/banner/newsbanner.png"),
          path: "",
          title: "新闻资讯",
          content: "News",
        },
        {
          img: require("@/assets/img/banner/newsbanner.png"),
          path: "",
          title: "新闻资讯",
          content: "News",
        },
      ],
      rencaiList: [
        {
          img: require("@/assets/home/zhaoxiannacai.png"),
          path: "",
          title: "招贤纳才",
          content: "Recruit talents",
        },
      ],
      smartList: [
        {
          img: require("@/assets/home/zhinengkeji.png"),
          path: "",
          title: "佳裕达—智能科技提质增效",
          content: "Intelligent technology improves quality and efficiency",
        },
        {
          img: require("@/assets/img/banner/zhinengbanner.png"),
          path: "",
          title: "佳裕达—智能科技提质增效",
          content: "Intelligent technology improves quality and efficiency",
        },
        {
          img: require("@/assets/img/banner/zhinengbanner.png"),
          path: "",
          title: "佳裕达—智能科技提质增效",
          content: "Intelligent technology improves quality and efficiency",
        },
      ],
      navList: [
        {
          name: "首页",
          path: "/",
          children: [],
        },
        {
          name: "物流科技",
          path: "/software",
          children: [],
        },
        {
          name: "产品服务",
          path: "/service",
          children: [],
        },
        {
          name: "智能科技",
          path: "/smartTechnology",
          children: [],
        },
        // {
        //   name: "新闻资讯",
        //   path: "/newsInformation",
        //   children: []
        // },
        {
          name: "人才招聘",
          path: "/personnelRecruitment",
          children: [],
        },
        {
          name: "投资者关系",
          link: "/theListedWebsites",
          children: [],
        },
        // {
        //   name: "社会责任",
        //   path: "/socialResponsibility",
        //   children: [],
        // },
        //  {
        //   name: "400-8888-8888",
        //   path: "/",
        //   children: []
        // }
      ],
    };
  },
  created() {
    setInterval(() => {
      // let ranArr = []
      let index = Math.floor(Math.random() * this.creList.length);
      //  if(ranArr.includes(index)) return
      // console.log(val[6].name,'9999999');
      this.navList[5] ? (this.navList[5].name = this.creList[index]) : "";
    }, 3000);
    this.navClick(0, "首页");
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    this.newsDeClick();
  },
  computed: {
    routes() {
      return this.$route.fullPath;
    },
  },
  watch: {
    routes(newValue) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.navList.map((item, index) => {
        if (item.path == newValue) {
          this.navIndex = index;
        }
      });
    },
    // navList:{
    //   handler(val){
    //   setInterval(() => {
    //     // let ranArr = []
    //      let index = Math.floor(Math.random()*(this.creList.length))
    //     //  if(ranArr.includes(index)) return
    //     // console.log(val[6].name,'9999999');
    //     val[5].name = this.creList[index]
    //   }, 10000);

    //   },
    //   immediate:true,
    //   deep:true
    //   // console.log(val);
    // }
  },
  methods: {
    newsDeClick() {
      if (this.$route.fullPath == "/newDetail") {
        this.$refs.nav ? (this.$refs.nav.className = "navv") : "";
        this.imgUrl = imgLogo2;
        this.phoneIcon = PhoneIcon;
      }
    },
    handleScroll() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 10) {
        this.$refs.nav ? (this.$refs.nav.className = "navv" || "") : "";
        this.imgUrl = imgLogo2;
        this.phoneIcon = PhoneIcon;
      } else {
        if (
          this.$route.fullPath != "/newDetail" &&
          this.$route.fullPath != "/cooperationConsultation"
        ) {
          this.$refs.nav ? (this.$refs.nav.className = "nav") : "";
          this.imgUrl = imgLogo;
          this.phoneIcon = PhoneIcon2;
        }
      }
    },
    scrollEvent(e) {
      console.log(e);
      if (this.$refs.nav.style.top > 10) {
        this.$refs.nav.style.backgroundColor = "#fff";
        this.$refs.aa.style.color = "#333";
      }
    },
    navClick(index, name) {
      if (index == 5) return;
      this.navIndex = index;
      sessionStorage.setItem("navIndex", index);
      this.menuName = name;
    },
    jumpOutlink() {
      window.open("https://ir.jayud.com/");
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  position: relative;

  .nav {
    z-index: 999;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    height: 68px;
    width: 100%;

    .logo {
      width: 300px;
      height: 68px;

      img {
        width: 182px;
        height: 37px;
        margin-left: 88px;
        margin-top: 16px;
      }
    }

    .menu {
      width: 1200px;
      // margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: #fff;
      text-align: center;
      line-height: 68px;
      margin-left: 58px;

      a,
      .divStyle {
        display: block;
        color: #fff;
        width: 119px;
        height: 68px;
      }

      .menu-item:hover,
      .active {
        background-color: #b7d0ff;
        border-bottom: #fff 6px solid;
      }

      div {
        width: 119px;
        height: 68px;
        //  margin-left: 10px;
      }
    }

    .lang {
      display: flex;
      font-size: 18px;
      width: 27%;
      color: #fff;
      text-align: center;
      line-height: 68px;
      margin-left: 30px;

      .el-dropdown {
        font-size: 18px;
        color: #fff;
      }

      .phone {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 74px;
        width: 100%;
        white-space: nowrap;
        margin: 0 34%;
      }

      .phoneNum {
        width: 100%;
        height: 68px;
        margin-left: 24px;
      }
    }
  }

  .navv {
    z-index: 999;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #fff;
    display: flex;
    height: 68px;
    width: 100%;
    box-shadow: 0px 3px 16px 0px #e5e5e5;

    .logo {
      width: 300px;
      height: 68px;

      img {
        width: 182px;
        height: 37px;
        margin-left: 88px;
        margin-top: 16px;
      }
    }

    .menu {
      width: 1200px;
      // margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: #fff;
      text-align: center;
      line-height: 68px;
      margin-left: 58px;

      a,
      .divStyle {
        display: block;
        color: #333;
        width: 119px;
        height: 68px;
      }

      a:focus,
      .divStyle:focus {
        color: #fff;
      }

      a:hover,
      .divStyle:hover {
        color: #fff;
      }

      .menu-item:hover,
      .active {
        background-color: #b7d0ff;
        border-bottom: #357bff 6px solid;

        a {
          color: #fff;
        }
      }

      div {
        // margin-left: 20px;
        width: 119px;
        height: 68px;
        // margin-left: 10px;
      }
    }

    .lang {
      width: 27%;
      margin-left: 30px;
      display: flex;
      font-size: 18px;
      color: #333;
      text-align: center;
      line-height: 68px;

      .el-dropdown {
        font-size: 18px;
        color: #333;
      }

      .phone {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 74px;
        width: 100%;
        margin: 0 34%;
        white-space: nowrap;
      }

      .phoneNum {
        width: 100%;
        height: 68px;
        margin-left: 24px;
      }
    }
  }

  .banner {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 76px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 100px;

    .title {
      text-align: center;
      font-size: 76px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ffffff;
      line-height: 100px;
    }

    .content {
      text-align: center;
      font-size: 68px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ffffff;
      line-height: 90px;
      white-space: nowrap;
    }
  }
}

/deep/.el-dropdown-link.el-dropdown-selfdefine {
  width: 68px;
}

/deep/button.el-carousel__arrow.el-carousel__arrow--left {
  margin-left: 80px;
  width: 50px;
  height: 50px;
}

/deep/button.el-carousel__arrow.el-carousel__arrow--right {
  margin-right: 80px;
  width: 50px;
  height: 50px;
}

/deep/i.el-icon-arrow-left {
  font-size: 18px;
}

/deep/i.el-icon-arrow-right {
  font-size: 18px;
}
</style>
