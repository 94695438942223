<template>
    <div class="personnelManager">
        <div class="tit">
            <div class="tit_heart">
                <span class="tit_Text">人才招聘</span><i class="el-icon-arrow-right"></i>
                <span class="tit_Icon" @click="back()">社会招聘</span><i class="el-icon-arrow-right"></i><span class="tit_Icon">职位详情</span>
            </div>
        </div>
        <div class="con">
            <div class="applyForPosition">
                <p class="position_left">职位名称：大客户销售（物流）</p>
                <el-button type="primary" disabled>申请职位</el-button>
            </div>
        </div>
        <div class="introduce">
            <div class="introduce_top">
                <!-- <p class="sty" style="margin-right:383px">招聘人数：1人</p> -->
                <!-- <p class="sty" style="margin-right:335px">招聘有效期：2月～5月</p> -->
                <p class="sty">薪酬范围：15000-30000</p>
            </div>
            <div class="introduce_bottom" style="margin-top:16px">
                <p class="sty" style="margin-right:360px">学历要求：大专学历</p>
                <p class="sty">经验要求：4年以上相关经验</p>
            </div>
        </div>
        <div class="operatingDuty">
            <div class="operatingDuty_text">工作职责</div>
            <ul class="operatingDuty_item">
                <li>1、客户开发与维护：负责开发和拓展国际物流KA（关键客户）业务，重点聚焦海运方向，同时熟悉海陆空铁中至少两种运输方式，满足客户多元化需求；</li>
                <li>2、项目管理与执行：负责KA客户项目全流程管理，协调公司内部资源，确保项目按时高效完成，达成销售目标；</li>
                <li>3、市场洞察与竞争分析：关注国际物流市场动态，分析竞争对手，制定差异化竞争策略，提升公司竞争力；</li>
                <li>4、团队协作与支持：协助团队成员完成客户拜访、项目跟进等工作，分享销售经验，提升团队整体销售能力。</li>
            </ul>
        </div>
        <div class="operatingDuty" style="padding:43px 0 54px;">
            <div class="operatingDuty_text">岗位要求</div>
            <ul class="operatingDuty_item">
                <li>1、教育背景：本科及以上学历，国际贸易、物流管理、市场营销等相关专业优先；</li>
                <li>2、语言能力：英语流利，具备良好的听说读写能力，能够熟练使用英语进行商务沟通和邮件往来；</li>
                <li>3、工作经验：3年以上国际物流行业销售经验，熟悉海运及相关联的运输方式（如陆运、空运、铁运）的业务流程和市场特点；</li>
                <li>4、客户资源与供应商资源：拥有丰富的国际物流KA客户资源和供应商资源；</li>
                <li>5、其他要求：具备良好的沟通能力、团队协作精神和抗压能力，能够适应快节奏的工作环境。</li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default{
        data(){
            return{
                bacKShow:null
            }
        },
        methods:{
            back(){
                this.bacKShow = 1
                this.$emit('bacKShow',this.bacKShow)
            }
        }
    }
</script>

<style lang="less" scoped>
.personnelManager{
    .tit{   
        background: #F4F3F3;
        height: 60px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 60px;
        .tit_heart{
            margin: 0 auto;
            width: 1200px;
            .tit_Text{
                margin-right: 11px;
            }
            .tit_Icon{
                margin: 11px;
            }
        }
    }
    .con{
        margin: 0 auto;
        width: 1200px;
        padding-top: 38px;
        .applyForPosition{
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .position_left{
                font-size: 18px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #333333;
                line-height: 24px;
            }
            /deep/.el-button--primary.is-disabled{
                background: #357BFF;
                border: #357BFF;
                border-radius: 2px;
            }
        }
    }
    .introduce{
        padding: 39px 0 37px;
        width: 1200px;
        margin: 0 auto;
        border-bottom: 1px solid #CCCCCC;
        .introduce_top,.introduce_bottom{
            display: flex;
            .sty{
                font-size: 16px;
                font-family: MicrosoftYaHei;
                color: #333333;
                line-height: 21px;
            }
        }
    }
    .operatingDuty{
        width: 1200px;
        margin: 0 auto;
        padding-top: 36px;
        .operatingDuty_text{
            font-size: 18px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #333333;
            line-height: 24px;
        }
        .operatingDuty_item{
            padding: 29px 0 0 72px;
            li{
                font-size: 16px;
                font-family: MicrosoftYaHei;
                color: #333333;
                line-height: 21px;
                margin-bottom: 12px;
            }
        }
    }
}
</style>