<template>
  <div class="all">
    <div class="content" v-if="show==1">
      <!-- <div class="tab">
        <div class="tab_left">社会招聘</div>
        <div class="tab_right">校园招聘</div>
      </div> -->
      <div class="body">
        <!-- <p class="positionSearch">职位搜索</p>
        <div class="inp">
          <el-input
            v-model="input"
            placeholder="请输入职位关键字"
            disabled
          ></el-input>
          <el-button type="primary" disabled>
            <img
              src="../../../assets/icon/talentRecruitment/sousuo@2x.png"
              alt=""
            />
          </el-button>
        </div>
        <div class="choice">
          <div class="choiceO">
            <span class="place">工作地点：</span>
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
              disabled
              >全选</el-checkbox
            >
            <div style="margin: 0 13px"></div>
            <el-checkbox-group
              v-model="checkedCities"
              @change="handleCheckedCitiesChange"
            >
              <el-checkbox
                v-for="city in cities"
                :label="city"
                :key="city"
                disabled
                >{{ city }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <div class="category" style="margin-top: 24px">
            <span class="place">职位类别：</span>
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
              disabled
              >全选</el-checkbox
            >
            <div style="margin: 0 13px"></div>
            <el-checkbox-group
              v-model="categoryName"
              @change="handleCheckedCitiesChange"
            >
              <el-checkbox
                v-for="category in categorys"
                :label="category"
                :key="category"
                disabled
                >{{ category }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div> -->
        <p class="positionList" style="padding: 46px 0">职位列表</p>
        <div class="tabs">
          <el-table :data="tableData" style="width: 100%" @row-click="isShow" @row-dblclick="isShow">
            <el-table-column prop="positionName" label="职位名称" align="left" width="270"></el-table-column>
            <el-table-column prop="workLocation" label="工作地点" align="left" width="310"></el-table-column>
            <el-table-column prop="educationalRequirements" label="学历要求" align="left" width="260"></el-table-column>
            <el-table-column prop="yearsOfService" label="工作年限" align="left"></el-table-column>
            <el-table-column prop="salaryRange" label="薪资范围" align="right"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <FBAsalesman v-if="show==2" @bacKShow="bacKShow"></FBAsalesman>
    <CustomerServiceSpecialist v-if="show==3" @bacKShow="bacKShow"></CustomerServiceSpecialist>
    <GodownKeeper v-if="show==4" @bacKShow="bacKShow"></GodownKeeper>
    <Telemarketing v-if="show==5" @bacKShow="bacKShow"></Telemarketing>
    <PersonnelManager v-if="show==6" @bacKShow="bacKShow"></PersonnelManager>
    <FinanceManager v-if="show==7" @bacKShow="bacKShow"></FinanceManager>
    <LogisticsSales v-if="show==10" @bacKShow="bacKShow"></LogisticsSales>
    <operator v-if="show==9" @bacKShow="bacKShow"></operator>
    <KeyAccountSales v-if="show==8" @bacKShow="bacKShow"></KeyAccountSales>
  </div>
</template>

<script>
import KeyAccountSales from './positionDetails/KeyAccountSales.vue'
import operator from './positionDetails/operator.vue'
import LogisticsSales from './positionDetails/LogisticsSales.vue'
import PersonnelManager from './positionDetails/HR&AdminManager.vue'
import FinanceManager from './positionDetails/financeManager.vue'
import FBAsalesman from './positionDetails/FBAsalesman.vue'
import CustomerServiceSpecialist from './positionDetails/customerServiceSpecialist.vue'
import GodownKeeper from './positionDetails/godownKeeper.vue'
import Telemarketing from './positionDetails/telemarketing.vue'
const cityOptions = [
  "北京",
  "上海",
  "广州",
  "深圳",
  "南京",
  "武汉",
  "宁波",
  "江门",
  "香港",
];
const positionCategory = [
  "行政",
  "市场营销",
  "客服",
  "财务",
  "IT",
  "采购",
  "高级管理",
  "运营",
  "其他",
];
export default {
  components:{
    PersonnelManager,
    FinanceManager,
    FBAsalesman,
    CustomerServiceSpecialist,
    GodownKeeper,
    Telemarketing,
    LogisticsSales,
    operator,
    KeyAccountSales
  },
  data() {
    return {
      input: "",
      checkAll: false,
      checkedCities: [""],
      categoryName: [""],
      cities: cityOptions,
      isIndeterminate: false,
      categorys: positionCategory,
      tableData: [
          {
          positionName: "物流销售（专员/主管/经理）",
          workLocation: "广东-深圳市-龙岗区",
          educationalRequirements: "大专学历以上",
          yearsOfService: "有相关工作经验",
          salaryRange: "5000-20000元",
        },
          {
          positionName: "海运/铁路操作员",
          workLocation: "广东-深圳市-龙岗区",
          educationalRequirements: "大专",
          yearsOfService: "2年以上经验",
          salaryRange: "7000-16000元",
        },
          {
          positionName: "大客户销售（物流）",
          workLocation: "广东-深圳市-龙岗区",
          educationalRequirements: "本科及以上学历",
          yearsOfService: "4年以上相关经验",
          salaryRange: "15000-30000元",
        },
        // {
        //   positionName: "FBA业务员",
        //   workLocation: "广东-深圳市-龙岗区",
        //   educationalRequirements: "大专",
        //   yearsOfService: "经验不限",
        //   salaryRange: "5000-20000元",
        // },
        // {
        //   positionName: "客服专员",
        //   workLocation: "广东-深圳市-龙岗区",
        //   educationalRequirements: "大专",
        //   yearsOfService: "经验不限",
        //   salaryRange: "6000-8000元",
        // },
        // {
        //   positionName: "仓库管理员",
        //   workLocation: "广东-深圳市-龙岗区",
        //   educationalRequirements: "大专",
        //   yearsOfService: "经验不限",
        //   salaryRange: "6000-8000元",
        // },
        // {
        //   positionName: "电话销售",
        //   workLocation: "广东-深圳市-龙岗区",
        //   educationalRequirements: "大专",
        //   yearsOfService: "经验不限",
        //   salaryRange: "5000-20000元",
        // },
        // {
        //   positionName: "人事行政经理",
        //   workLocation: "广东-深圳市-盐田区",
        //   educationalRequirements: "大学本科",
        //   yearsOfService: "经验不限",
        //   salaryRange: "20-25K",
        // },
        // {
        //   positionName: "财务经理",
        //   workLocation: "广东-深圳市-盐田区",
        //   educationalRequirements: "大学本科",
        //   yearsOfService: "三年以上",
        //   salaryRange: "20-25K",
        // },
      ],
      show:1
    };
  },
  methods: {
    handleCheckAllChange(val) {
      this.checkedCities = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },
    isShow(val){
      if(val.positionName=="FBA业务员"){
        this.show=2
      }else if(val.positionName=="客服专员"){
        this.show=3
      }else if(val.positionName=="仓库管理员"){
        this.show=4
      }else if(val.positionName=="电话销售"){
        this.show=5
      }else if(val.positionName=="人事行政经理"){
        this.show=6
      }else if(val.positionName=="财务经理"){
        this.show=7
      }else if( val.positionName=="大客户销售（物流）"){
        this.show=8
      }else if(val.positionName=="海运/铁路操作员"){
        this.show=9
      }else if(val.positionName=="物流销售（专员/主管/经理）"){
        this.show=10
      }
    },
    bacKShow(val){
      this.show=val
    }
  },
};
</script>

<style lang="less" scoped>
.all{
  .content {
    .tab {
      display: flex;
      justify-content: center;
      margin-top: -22px;
      .tab_left {
        width: 188px;
        height: 45px;
        background: #357bff;
        box-shadow: 0px 2px 9px 0px rgba(191, 191, 191, 0.5);
        border-radius: 2px 0px 0px 2px;
        font-size: 18px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        line-height: 45px;
        text-align: center;
        z-index: 900;
      }
      .tab_right {
        width: 188px;
        height: 45px;
        background: #ffffff;
        box-shadow: 0px 2px 9px 0px rgba(191, 191, 191, 0.5);
        border-radius: 0px 2px 2px 0px;
        font-size: 18px;
        font-family: MicrosoftYaHei;
        color: #333333;
        text-align: center;
        line-height: 45px;
        z-index: 900;
      }
    }
    .body {
      width: 1200px;
      margin: 0 auto;
      .positionSearch,
      .positionList {
        font-size: 18px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #333333;
        line-height: 24px;
        padding: 49px 0 36px;
      }
      .inp {
        display: flex;
        width: 743px;
        height: 42px;
        /deep/.el-input__inner {
          border-radius: 2px 0px 0px 2px;
          height: 42px;
          border: 1px solid #d2d2d2;
          background-color:#ffffff;
          &::placeholder {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: #666666;
          }
        }
        /deep/.el-button {
          border-radius: 0px 2px 2px 0px;
          height: 42px;
        }
        /deep/.el-button--primary.is-disabled {
          background: #357bff;
          border-color: #357bff;
        }
        img {
          width: 19px;
          height: 18px;
        }
      }
      .choice {
        .choiceO,
        .category {
          margin-top: 35px;
          display: flex;
          .place {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: #333333;
            line-height: 21px;
          }
          /deep/.el-checkbox {
            color: #333333;
          }
          /deep/.el-checkbox__input.is-disabled .el-checkbox__inner{
            background-color:#ffffff;
          }
          /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background: #357bff;
            border-color: #357bff;
          }
          /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
            color: #333333;
          }
          /deep/.el-checkbox__input.is-disabled + span.el-checkbox__label {
            color: #333333;
          }
        }
      }
      .tabs{
        margin-bottom: 60px;
        /deep/.el-table thead{
          color: #333333;
          font-size: 16px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        }
        /deep/.el-table td.el-table__cell div{
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #333333;
        }
        /deep/.el-table th.el-table__cell>.cell{
          font-weight: bold;
        }
      }
    }
  }
}
</style>