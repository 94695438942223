<template>
  <div class="content">
    <!-- 顶部 -->
    <div class="top">
      <div class="topBG">
        <div class="topIcon">
          <div class="topIcon1"></div>
          <div class="topIcon2"></div>
          <div class="topIcon1"></div>
        </div>
        <img src="@/assets/JYDLogoB.png" @click="goHome" alt class="logoWeb" />
      </div>
      <div class="topList">
        <div class="brief" @click="handleLeft">集团简介</div>
        <div class="brief" @click="handleLeft1">主要指标</div>
        <div class="brief" @click="handleLeft2">股票代码</div>
        <div class="brief" @click="handleLeft3">财务信息</div>
        <div class="brief" @click="handleLeft4">高管及董事会</div>
        <div class="brief" @click="handleLeft5">集团新闻</div>
      </div>
    </div>
    <!-- 物流科技 -->
    <div class="topF">
      <div class="topL">
        <div class="title" id="brief1">
          <span class="circle"></span> 物流科技
        </div>
        <div class="contents">
          <div class="pNewList">
            <p class="pNewListP">
              深圳市佳裕达物流科技有限公司成立于2009年，总部位于广东省深圳市。经过十多年的不懈努力，现已发展成为立足粤港澳大湾区、面向全国、布局全球的大型AAAA级综合服务型物流企业。服务范围涵盖空运服务、海运服务、陆运、清关、物流配送、仓储、电子商务、跨境运输、物流软件系统开发等各个领域。
            </p>
            <p class="pNewListP">
              深圳市佳裕达物流科技有限公司以致力于发展“成为全球领先综合物流服务商”为愿景，将先进的物流技术和科学的营运管理融入到为客户提供安全、便捷的个性化物流服务当中，依托资源优势、资源整合能力和精益求精的科学管理方式，服务全球各行业优秀企业，有效降低企业物流成本，提高经济效益，不断为客户创造价值！
            </p>
            <p class="pNewListP">
              2023年4月21日，集团旗下的Jayud Global Logistics Ltd（以下简称“佳裕达”），成功在美国纳斯达克IPO上市，股票代码JYD。至此，佳裕达全面开启全球化旅程！
            </p>
            <p class="pNewListP">
              佳裕达与多个国内外一线知名品牌合作，涉及领域包括新能源、汽车、医疗、消费电子、母婴健康、儿童玩具、户外运动、智能穿戴等多个行业。其中与宁德时代、VIVO、OPPO、欣旺达、小米、联想、TCL、安克、大族激光、雅昌、中粮集团、派克、伊莱克斯等数家行业头部企业保持紧密的战略合作关系。
            </p>
          </div>
        </div>
      </div>
      <div class="topR"></div>
    </div>
    <!-- 主要指标 -->
    <div class="indicator">
      <div class="indicatorT">
        <div class="titles">
          <div class="titles1" id="brief2">
            <span class="circle"></span>主要指标
            <div class="circles1"></div>
          </div>
          <div class="titles2">查看所有财经新闻稿</div>
        </div>
        <!-- 横线 -->
        <div class="line">
          <div class="lineLong"></div>
          <div class="circleBig">
            <div class="circles"></div>
          </div>
          <div class="lines"></div>
          <div class="circleBig">
            <div class="circles"></div>
          </div>

          <div class="lines"></div>
          <div class="circleBig">
            <div class="circles"></div>
          </div>
          <div class="lineLong"></div>
        </div>
        <!-- 边框 -->
        <div class="itemBox">
          <div class="boxItem">
            <p class="year">2022 年 2 月 1 日</p>
            <div class="listContent">
              <p class="pList">佳裕达发布2021年</p>
              <p class="pListse">第四季度财报</p>
            </div>
            <p class="pNew">
              阅读新闻稿
              <img src="@/assets/arrow.png" alt style="margin-left: 10%" class="arrow" />
            </p>
            <div class="lineList"></div>
          </div>
          <div class="boxItem2">
            <p class="year">2022 年 1 月 17 日</p>
            <div class="listContent">
              <p class="pList">佳裕达将于2022年2月1日</p>
              <p class="pListse">星期二发布2021年第四季度</p>
              <p class="pListses">业绩</p>
            </div>
            <p class="pNew">
              阅读新闻稿
              <img src="@/assets/arrow.png" alt style="margin-left: 10%" class="arrow" />
            </p>
            <div class="lineList"></div>
          </div>
          <div class="boxItem3">
            <p class="year">2021 年 11 月 4 日</p>
            <div class="listContent">
              <p class="pList">佳裕达宣布季度股息</p>
            </div>

            <p class="pNew">
              阅读新闻稿
              <img src="@/assets/arrow.png" alt style="margin-left: 10%" class="arrow" />
            </p>
            <div class="lineList"></div>
          </div>
        </div>
      </div>
      <div class="indicatorB">
        <div class="messageBG">
          <div class="message">
            <span class="circle" style="margin-right: 2%" id="brief3"></span>股票信息
            <span class="circle" style="margin-left: 2%"></span>
          </div>
        </div>
        <div class="messageBox">
          <div class="messageLeft">
            <p class="tit1">市场/符号</p>
            <p class="tit2">纽约证券交易所</p>
          </div>
          <div class="messagemiddle">
            <p class="tit1">价格</p>
            <p class="tit3">220.20</p>
          </div>
          <div class="messageRight">
            <p class="tit1">改变</p>
            <p class="tit4">-2.25 (-1.01%)</p>
          </div>
        </div>
        <div class="messageList">
          <div class="messageT">市场/符号</div>
          <div class="messageB item">纽约证券交易所:佳裕达</div>
        </div>
        <div class="messageList">
          <div class="messageT">价格</div>
          <div class="messageB">220.20</div>
        </div>
        <div class="messageList">
          <div class="messageT">改变</div>
          <div class="messageB">-2.25 (-1.01%)</div>
        </div>
        <div class="messageList">
          <div class="messageT">体积</div>
          <div class="messageB">2.20M</div>
        </div>
        <div class="messageList">
          <div class="messageT">天范围</div>
          <div class="messageB">218.57 - 222.30</div>
        </div>
        <div class="messageList">
          <div class="messageT">52周以来范围</div>
          <div class="messageB">167.40 - 233.72</div>
        </div>
      </div>
    </div>
    <!-- 财务 -->
    <div class="financial">
      <div class="financialL" id="brief4">
        <div class="cirlesL">
          <p class="list1">$27.8B</p>
          <span class="content1">2021年第四季度综合收入</span>
        </div>
        <div class="cirlesL">
          <p class="list1">29.3M</p>
          <span class="content1">2021年第四季度日均包裹量</span>
        </div>
        <div class="cirlesL">
          <p class="list1">$15.0B</p>
          <span class="content1">2021年全年经营活动现金流</span>
        </div>
        <p class="pList">宣布季度股息为 1.52 美元，每股比上年增长 49%</p>
        <p class="pList1">资料来源：2021 年第四季度财务</p>
        <p class="pList2">有关我们财务状况的完整信息，请参阅我们的定期文件</p>
        <div class="button1">查看对账</div>
      </div>
      <div class="financialR">
        <div class="topFinancial">
          <div class="times">
            <span class="circle"></span> 2022 年 2 月 1 日 •
            美国东部标准时间上午 6:00
          </div>
          <h3 class="year1">2021财年</h3>
          <p class="year2">截至 2021 年 12 月 31 日的财政年度</p>
          <div class="button2">查看年度报表</div>
        </div>
        <div class="botFinancial">
          <div class="contentAll">
            <div class="contents">财报发布</div>
            <div class="contents1">
              <img src="@/assets/HTML.png" alt />
              <div class="lists1">HTML</div>
              <img src="@/assets/pdf.png" alt />
              <div class="lists1">PDF</div>
            </div>
          </div>
          <div class="contentAll">
            <div class="contents">收益网络直播</div>
            <div class="contents2">
              <img src="@/assets/shengyinkai.png" alt />
              <div class="lists1">AUDIO</div>
            </div>
          </div>
          <div class="contentAll">
            <div class="contents">10-K</div>
            <div class="contents3">
              <img src="@/assets/HTML.png" alt />
              <div class="lists1">HTML</div>
              <img src="@/assets/pdf.png" alt />
              <div class="lists1">PDF</div>
            </div>
          </div>
          <div class="contentAll">
            <div class="contents">XBRL</div>
            <div class="contents4">
              <img src="@/assets/HTML.png" alt />
              <div class="lists1">HTML</div>
              <img src="@/assets/XLSX.png" alt />
              <div class="lists1">XLSX</div>
              <img src="@/assets/ZIP.png" alt />
              <div class="lists1">ZIP</div>
            </div>
          </div>
          <div class="contentAll">
            <div class="contents">2021年第四季度财务信息</div>
            <div class="contents5">
              <img src="@/assets/XLSX.png" alt />
              <div class="lists1">XLSX</div>
            </div>
          </div>
          <div class="contentAll">
            <div class="contents">2021 年第四季度历史财务信息</div>
            <div class="contents6">
              <img src="@/assets/XLSX.png" alt />
              <div class="lists1">XLSX</div>
            </div>
          </div>
          <div class="contentAll">
            <div class="contents">收益报告</div>
            <div class="contents7">
              <img src="@/assets/pdf.png" alt />
              <div class="lists1">PDF</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 高管及董事会 -->
    <div class="directors">
      <div class="titles" id="brief5">
        <span class="circle" style="margin-right: 1%"></span>高管及董事会
        <span class="circle" style="margin-left: 1%"></span>
      </div>
      <div class="carousel">
        <el-carousel :interval="4000" type="card" height="450px">
          <el-carousel-item v-for="item in imgs" :key="item.url">
            <!-- <h3 class="medium">{{ item }}</h3> -->
            <img :src="item.url" alt style="width: 80%" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="picWeb">
        <img src="../../assets/rightWeb.png" alt="" class="pic1">
        <img src="../../assets/people2.png" alt="" class="pic2">
        <img src="../../assets/people3.png" alt="" class="pic3">
        <img src="../../assets/people1.png" alt="" class="pic4">
        <img src="../../assets/people4.png" alt="" class="pic5">
        <img src="../../assets/people5.png" alt="" class="pic6">
        <img src="../../assets/rightWeb.png" alt="" class="pic7">
        <div class="yuan"></div>
      </div>-->
      <div class="carouselWeb">
        <el-carousel :interval="4000" type="card" height="450px">
          <el-carousel-item v-for="item in imgs" :key="item.url">
            <!-- <h3 class="medium">{{ item }}</h3> -->
            <img :src="item.url" alt style="width: 80%" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 集团新闻 -->
    <div class="companyNews">
      <div class="titles" id="brief6">
        <span class="circle" style="margin-right: 1%"></span>集团新闻
        <span class="circle" style="margin-left: 1%"></span>
      </div>
      <div class="tabs">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="政策法规" name="first">政策法规</el-tab-pane>
          <el-tab-pane label="最新签约" name="second">
            <div class="companyNewsBox">
              <div class="companyNewsLeft">
                <div class="tops">
                  <img src="@/assets/leftWeb.png" alt class="leftWeb" />
                  <img src="@/assets/carPc.png" alt class="imgCar" />
                  <p class="ppList">第30个全国节能宣传周 | 佳裕达：为“绿色包装”打call</p>
                  <img src="@/assets/arrowWeb.png" alt class="arrow" />
                  <img src="@/assets/rightWeb.png" alt class="rightWeb" />
                  <div class="shadow">2020/ 10.02</div>
                </div>
              </div>
              <div class="companyNewsRight">
                <div class="newsBox">
                  <div class="nesTop">
                    <p class="newLists">
                      <span class="cirled"></span>
                      【签约】深圳市佳丽文化科技有限公司
                    </p>
                    <p class="newListe">感谢深圳市佳丽文化科技有限公司信任我们，将20吨的货物交给我们6……</p>
                  </div>
                  <div class="nesBot">
                    <div class="date">2020/ 10.02</div>
                    <img src="@/assets/arrowWeb.png" alt class="newImg" />
                  </div>
                </div>

                <div class="newsBox">
                  <div class="nesTop">
                    <p class="newLists">
                      <span class="cirled"></span>
                      【签约】深圳市佳丽文化科技有限公司
                    </p>
                    <p class="newListe">感谢深圳市佳丽文化科技有限公司信任我们，将20吨的货物交给我们6……</p>
                  </div>
                  <div class="nesBot">
                    <div class="date">2020/ 10.02</div>
                    <img src="@/assets/arrowWeb.png" alt class="newImg" />
                  </div>
                </div>

                <div class="newsBox">
                  <div class="nesTop">
                    <p class="newLists">
                      <span class="cirled"></span>
                      【签约】深圳市佳丽文化科技有限公司
                    </p>
                    <p class="newListe">感谢深圳市佳丽文化科技有限公司信任我们，将20吨的货物交给我们6……</p>
                  </div>
                  <div class="nesBot">
                    <div class="date">2020/ 10.02</div>
                    <img src="@/assets/arrowWeb.png" alt class="newImg" />
                  </div>
                </div>
              </div>
            </div>
            <div class="companyNewsBoxWeb">
              <div class="leftImg">
                <img src="@/assets/leftWeb.png" alt />
              </div>
              <div class="middleContent">
                <img src="@/assets/carWeb.png" alt />
                <div class="middleList">
                  <p class="middleP">第30个全国节能宣传周 | 佳裕达： 为“绿色包装”打call</p>
                  <p class="middleYear">2020/ 10.02</p>
                  <span class="arrow">
                    <img src="@/assets/arrowWeb.png" alt />
                  </span>
                </div>
              </div>
              <div class="rightImg">
                <img src="@/assets/rightWeb.png" alt />
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="集团动态" name="third">集团动态</el-tab-pane>
          <el-tab-pane label="行业信息" name="fourth">行业信息</el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 底部 -->
    <!-- <div class="foot">
      <div class="footlist1">
        <img src="@/assets/JYDLOGO.png" alt />
        <div class="lined"></div>
      </div>
      <div class="footlist2">
        <div class="addressList">
          <span class="listSpan">
            <img src="@/assets/company.png" alt />
          </span>
          公司： 深圳｜东莞｜南京｜西安｜香港
        </div>
        <div class="addressList2">
          <span class="listSpan">
            <img src="@/assets/address.png" alt />
          </span>
          地址：深圳市盐田区沙头角保税区4栋4楼
        </div>
        <div class="addressList3">
          <span class="listSpans">
            <img src="@/assets/phone.png" alt />
          </span>电话： 0755-2851 1630 丨 0755-25595489
        </div>
      </div>
      <div class="footlist3">
        <img src="@/assets/code.png" alt style="width: 99px; height: 98px" />
        <p class="vedioList">关注微信公众号</p>
      </div>
      <div class="footlist4">
        <img src="@/assets/codeviode.png" alt style="width: 99px; height: 98px" />
        <p class="vedioList">企业宣传视频</p>
      </div>
    </div>-->
  </div>
</template>

<script>
// import { debounce } from "lodash";
export default {
  created() {
    console.log(this, "this");
  },
  data() {
    return {
      activeName: "second",
      imgs: [
        { url: require("@/assets/people1.png"), link: "/content" },
        { url: require("@/assets/people2.png"), link: "/content" },
        { url: require("@/assets/people3.png"), link: "/content" },
        { url: require("@/assets/people4.png"), link: "/content" },
        { url: require("@/assets/people5.png"), link: "/content" }
      ],
      title: [
        { name: "集团简介" },
        { name: "主要指标" },
        { name: "股票代码" },
        { name: "财务信息" },
        { name: "高管及董事会" },
        { name: "集团新闻" }
      ]
    };
  },
  methods: {
    goHome() {
      this.$router.push({
        path: "/"
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleLeft() {
      let temp = document.getElementById("brief1");
      temp.scrollIntoView({ block: "start", behavior: "smooth" });
    },
    handleLeft1() {
      let temp = document.getElementById("brief2");
      temp.scrollIntoView({ block: "start", behavior: "smooth" });
    },
    handleLeft2() {
      let temp = document.getElementById("brief3");
      temp.scrollIntoView({ block: "start", behavior: "smooth" });
    },
    handleLeft3() {
      let temp = document.getElementById("brief4");
      temp.scrollIntoView({ block: "start", behavior: "smooth" });
    },
    handleLeft4() {
      let temp = document.getElementById("brief5");
      temp.scrollIntoView({ block: "start", behavior: "smooth" });
    },
    handleLeft5() {
      let temp = document.getElementById("brief6");
      temp.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }
};
</script>

<style  lang="less" scoped>
.content {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 730px;
    background-image: url("~@/assets/tBG.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    justify-content: space-between;

    .topBG {
      width: 10%;
      margin-left: 2%;
      margin-top: 2%;
    }

    .topList {
      width: 90%;
      display: flex;
      // margin-left: 47%;
      justify-content: end;
      margin-top: 0.1%;

      .brief {
        height: 71px;
        font-size: 18px;
        width: 126px;
        font-family: Microsoft YaHei;
        color: #ffffff;
        line-height: 71px;
        margin-left: 10px;
        text-align: center;
      }
    }

    .brief:hover {
      background-color: #4095ff;
      border-bottom: 4px solid #fff;
      cursor: pointer;
    }
  }

  .topF {
    width: 100%;
    height: 454px;
    display: flex;

    .topL {
      width: 57%;
      height: 454px;
      background-image: url("~@/assets/introduceBG.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      .title {
        margin-left: 20%;
        margin-top: 30px;
        width: 100px;
        height: 19px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 38px;
        display: flex;
        align-items: center;
      }

      .contents {
        width: 75%;
        height: 330px;
        font-size: 14px;
        text-indent: 2em;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 28px;
        margin-left: 20%;
        margin-top: 20px;
      }
    }

    .topR {
      width: 43%;
      height: 454px;
      background-image: url("~@/assets/introduce.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }

  .indicator {
    width: 100%;
    height: 655px;

    .indicatorT {
      width: 100%;
      height: 66%;
      background-color: red;
      background-image: url("~@/assets/indicatorBG.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      .titles {
        display: flex;
        align-items: center;

        .titles1 {
          margin-top: 1%;
          margin-left: 11%;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          line-height: 30px;
          display: flex;
          align-items: center;
        }

        .titles2 {
          margin-left: 53%;
          margin-top: 1%;
          width: 14%;
          height: 50px;
          background: #ffffff;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          line-height: 50px;
          text-align: center;
        }
      }

      .line {
        width: 67%;
        margin-left: 15%;
        margin-top: 2%;
        display: flex;
        align-items: center;
      }

      .lineLong {
        height: 1px;
        width: 7%;
        background-color: #505050;
      }

      .lines {
        height: 1px;
        background-color: #505050;
        width: 40%;
      }

      .circleBig {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #b8bdc1;
        position: relative;
      }

      .circles {
        width: 12px;
        height: 12px;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        top: 3px;
        left: 3px;
      }

      .itemBox {
        width: 100%;
        height: 100%;
        display: flex;

        .boxItem {
          width: 19%;
          height: 70%;
          background-image: url("~@/assets/borderBG.png");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          margin-top: 0%;
          margin-left: 11%;
        }

        .boxItem:hover {
          background-image: url("~@/assets/borderItem.png");
          cursor: pointer;
        }

        .boxItem2:hover {
          background-image: url("~@/assets/borderItem.png");
          cursor: pointer;
        }

        .arrow:hover {
          background-image: url("~@/assets/arrowN.png");
          cursor: pointer;
        }

        .boxItem3:hover {
          background-image: url("~@/assets/borderItem.png");
          cursor: pointer;
        }

        .year {
          width: 124px;
          height: 15px;
          text-align: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          line-height: 57px;
          margin-left: 35%;
          margin-top: 15%;
        }

        .listContent {
          height: 20%;
          position: relative;

          .pList {
            font-size: 18px;
            font-family: Microsoft YaHei;
            margin-left: 8%;
            font-weight: bold;
            color: #ffffff;
            line-height: 21px;
            margin-top: 10%;
          }

          .pListse {
            font-size: 18px;
            font-family: Microsoft YaHei;
            margin-left: 8%;
            font-weight: bold;
            color: #ffffff;
            line-height: 21px;
            position: absolute;
            top: 52%;
            left: 0;
          }

          .pListses {
            font-size: 18px;
            font-family: Microsoft YaHei;
            margin-left: 8%;
            font-weight: bold;
            color: #ffffff;
            line-height: 21px;
            position: absolute;
            top: 100%;
            left: 0;
          }
        }

        .pNew {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          margin-left: 8%;
          line-height: 30px;
          margin-top: 7%;
          display: flex;
          align-items: center;
          height: 20%;
        }

        .lineList {
          height: 2px;
          background-color: #fff;
          width: 90%;
          margin-top: 3%;
          margin-left: 5%;
        }

        .boxItem2 {
          width: 19%;
          height: 70%;
          margin-top: 0;
          background-image: url("~@/assets/borderBG.png");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          margin-top: 0%;
          margin-left: 8.5%;
        }

        .boxItem3 {
          width: 19%;
          height: 70%;
          margin-top: 0;
          background-image: url("~@/assets/borderBG.png");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          margin-top: 0%;
          margin-left: 8.5%;
        }
      }
    }

    .indicatorB {
      width: 100%;
      height: 34%;
      background: #191c1f;

      .message {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 30px;
        text-align: center;
        padding-top: 1%;
      }

      .messageList {
        float: left;
        margin-top: 3%;
        margin-left: 7%;

        .messageT {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          line-height: 30px;
          text-align: center;
        }

        .messageB {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ff3d10;
          line-height: 30px;
          text-align: center;
        }

        .messageB.item {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          line-height: 30px;
          text-align: center;
        }
      }
    }
  }

  .circle {
    width: 14px;
    height: 14px;
    background: #ff3d10;
    border: 7px solid red;
    border-radius: 50%;
    display: inline-block;
  }

  .messageBox {
    display: none;
  }

  .financial {
    width: 100%;
    height: 664px;
    display: flex;
    background-image: url("~@/assets/financial.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .financialL {
      width: 50%;
      height: 100%;
      border-right: 1px solid #cbcbcb;

      .cirlesL {
        width: 100px;
        height: 100px;
        border: 1px solid #ff3d10;
        border-radius: 50%;
        margin-left: 30%;
        margin-top: 2.2%;

        .list1 {
          width: 66px;
          height: 28px;
          font-size: 22px;
          font-family: DIN Condensed;
          font-weight: bold;
          color: #ff3d10;
          line-height: 97px;
          // margin-top: 32%;
          margin-left: 13%;
          text-align: center;
          // margin-right: 10%;
        }

        .content1 {
          display: block;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          line-height: 30px;
          width: 186px;
          height: 17px;
          margin-left: 121%;
          margin-top: -29%;
        }
      }

      .pList {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-top: 3%;
        margin-left: 30%;
      }

      .pList1 {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-top: 5%;
        margin-left: 30%;
      }

      .pList2 {
        font-size: 10px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-left: 30%;
      }

      .button1 {
        width: 240px;
        height: 50px;
        background: #353232;
        text-align: center;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 45px;
        margin-left: 45%;
        margin-top: 7%;
      }
    }

    .financialR {
      width: 50%;
      height: 100%;

      .topFinancial {
        width: 100%;
        height: 278px;
        border-bottom: 1px solid #cbcbcb;

        .times {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          line-height: 30px;
          margin-top: 4%;
          margin-left: 2%;
        }

        .year1 {
          font-size: 32px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          line-height: 30px;
          margin-top: 5%;
          margin-left: 4%;
        }

        .year2 {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #666666;
          line-height: 30px;
          margin-top: 4%;
          margin-left: 4%;
        }

        .button2 {
          width: 240px;
          height: 50px;
          background: #1e1b1b;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          line-height: 46px;
          text-align: center;
          margin-top: 4%;
          margin-left: 4%;
        }
      }

      .botFinancial {
        width: 100%;
        height: 348px;

        .contentAll {
          display: flex;

          .contents {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            line-height: 30px;
            margin-left: 3%;
            margin-top: 1%;
          }

          .contents1 {
            display: flex;
            margin-left: 31%;
            margin-top: 1%;
            align-items: center;
          }

          .contents2 {
            display: flex;
            margin-left: 32%;
            margin-top: 1%;
            align-items: center;
          }

          .contents3 {
            display: flex;
            margin-left: 33.6%;
            margin-top: 1%;
            align-items: center;
          }

          .contents4 {
            display: flex;
            margin-left: 27.1%;
            margin-top: 1%;
            align-items: center;
          }

          .contents5 {
            display: flex;
            margin-left: 25.3%;
            margin-top: 1%;
            align-items: center;
          }

          .contents6 {
            display: flex;
            margin-left: 22%;
            margin-top: 1%;
            align-items: center;
          }

          .contents7 {
            display: flex;
            margin-left: 37.5%;
            margin-top: 1%;
            align-items: center;
          }

          .lists1 {
            width: 42px;
            height: 14px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 500;
            text-decoration: underline;
            color: #333333;
            line-height: 16px;
            margin-left: 1%;
          }
        }
      }
    }
  }

  .directors {
    width: 100%;
    height: 600px;
    background-image: url("~@/assets/directors.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .carouselWeb {
      display: none;
    }

    .titles {
      text-align: center;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      line-height: 30px;
      padding-top: 5%;
      height: 140px;
    }

    .carousel {
      width: 33%;
      height: 400px;
      margin-left: 33%;
      margin-top: 5%;

      // display: none;
      .el-carousel--horizontal {
        overflow-x: inherit;
      }

      .el-carousel.el-carousel--horizontal.el-carousel--card {
        width: 131%;
        padding-left: 37%;
        margin-left: -36%;
      }

      button.el-carousel__arrow.el-carousel__arrow--left {
        margin-top: -24%;
        margin-left: -90%;
      }

      button.el-carousel__arrow.el-carousel__arrow--right {
        margin-top: -24%;
        // display: none;
        margin-right: -100%;
      }

      ul.el-carousel__indicators.el-carousel__indicators--horizontal.el-carousel__indicators--outside {
        display: none;
      }
    }

    .picWeb {
      margin-top: 9%;
      margin-left: 22%;

      .pic1 {
        margin-left: -20%;
        margin-bottom: 5%;
      }

      .pic2 {
        margin-left: 9%;
      }

      .pic3 {
        margin-left: 7%;
      }

      .pic4 {
        margin-left: 6%;
      }

      .pic5 {
        margin-left: 9%;
      }

      .pic6 {
        margin-left: 8%;
      }

      .pic7 {
        margin-left: 8%;
        margin-bottom: 5%;
      }

      //   .yuan {
      //     width: 273px;
      //     height: 162px;
      //     border: 2px solid #FF3D10;
      //     border-radius: 0 0 50% 50%/0 0 100% 100% ;
      //     margin-left: 27.4%;
      //     margin-top: -9%;
      //     border-top: none;
      // }
    }
  }

  .companyNews {
    width: 100%;
    height: 780px;
    box-sizing: border-box;
    background-image: url("~@/assets/newsBG.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .titles {
      text-align: center;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      line-height: 62px;

      box-sizing: border-box;
    }

    .tabs {
      width: 70%;
      height: 100%;
      margin-left: 20%;

      .companyNewsBoxWeb {
        display: none;
      }

      .companyNewsBox {
        width: 100%;
        height: 100%;
        display: flex;

        .companyNewsLeft {
          width: 38%;
          height: 100%;

          .tops {
            height: 80%;
            width: 100%;
            position: relative;

            .imgCar {
              width: 100%;
              height: 612px;
            }

            .leftWeb {
              display: none;
            }

            .rightWeb {
              display: none;
            }

            .carWeb {
              display: none;
            }

            .ppList {
              position: absolute;
              top: 85%;
              left: 3%;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;
            }

            .arrow {
              position: absolute;
              top: 93%;
              left: 87%;
              width: 8%;
            }
          }

          .shadow {
            display: none;
          }
        }

        .companyNewsRight {
          width: 60%;
          height: 50%;
          margin-left: 1%;

          .newsBox {
            width: 100%;
            height: 195px;
            background: #ffffff;
            box-shadow: 2px 3px 7px 0px rgba(36, 36, 36, 0.06);
            border-radius: 8px;
            margin-top: 1%;

            .nesTop {
              height: 112px;
              border-bottom: 1px solid #eeeeee;
              position: relative;

              .newLists {
                width: 100%;
                height: 19px;
                position: absolute;
                top: 12%;
                left: 0%;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                margin-left: 8%;
                position: relative;

                .cirled {
                  width: 10px;
                  height: 10px;
                  background: #333333;
                  border-radius: 50%;
                  display: block;
                  position: absolute;
                  top: 35%;
                  left: -1%;
                }
              }

              .newListe {
                width: 100%;
                height: 17px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                margin-top: 7%;
                margin-left: 10%;
              }
            }

            .nesBot {
              position: relative;

              .date {
                width: 50px;
                height: 43px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                line-height: 28px;
                margin-left: 10%;
                margin-top: 3%;
              }

              .newImg {
                position: absolute;
                top: 50%;
                left: 86%;
              }
            }
          }
        }
      }
    }

    .el-tabs__nav.is-top {
      margin-left: 28%;
    }
  }

  .foot {
    width: 100%;
    height: 182px;
    background-color: #222830;
    display: flex;

    .footlist1 {
      margin-left: 563px;
      margin-top: 58px;
      position: relative;

      .lined {
        border-right: 1px solid #ccc;
        width: 1px;
        height: 78px;
        position: absolute;
        top: 1%;
        left: 140%;
      }
    }

    .footlist2 {
      width: 234px;
      height: 20px;
      margin-left: 100px;
      margin-top: 43px;
      position: relative;

      .addressList {
        width: 236px;
        height: 13px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 42px;
        position: absolute;
        top: 1%;
        left: 20%;
        position: relative;

        .listSpan {
          display: block;
          position: absolute;
          top: 22%;
          left: -10%;
        }
      }

      .addressList2 {
        width: 260px;
        height: 13px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 42px;
        position: absolute;
        top: 163%;
        left: 9%;
      }

      .addressList3 {
        width: 267px;
        height: 13px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 42px;
        position: absolute;
        top: 332%;
        left: 19%;
        position: absolute;

        .listSpans {
          display: block;
          position: absolute;
          top: 26%;
          left: -8%;
        }
      }
    }

    .footlist3 {
      margin-left: 7%;
      margin-top: 2%;
    }

    .footlist4 {
      margin-left: 4%;
      margin-top: 2%;
    }

    .vedioList {
      width: 84px;
      height: 12px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 18px;
      margin-left: 11%;
    }
  }
}

/deep/ul.el-carousel__indicators.el-carousel__indicators--horizontal.el-carousel__indicators--outside {
  display: none;
}</style>