<template>
  <div class="introduce">
    <div class="product">
      <div class="introduction">
        <div class="product_title"> 集团简介 </div>
        <!-- <ProductService /> -->
        <div class="text">
          <div v-for="(it, id) in introduction" :key="'text' + id">
            {{ it }}
          </div>
        </div>
        <img src="@/assets/jyd_home/introduce/bg1.png" alt="" class="introduction_Img" preview="1" preview-text="集团简介">
      </div>
      <div class="structure">
        <div class="product_title"> 荣誉资质 </div>
        <img src="@/assets/jyd_home/introduce/bg2.png" alt="" class="structure_Img" preview="2" preview-text="荣誉资质">
      </div>
      <div class="development">
        <div class="product_title"> 发展历程 </div>
        <Development />
      </div>
      <div class="strategy">
        <div class="product_title"> 集团战略 </div>
        <!-- <Development /> -->
        <div class="strategy_center">
          <img src="@/assets/jyd_home/introduce/bg4.png" alt="" class="strategy_Img" preview="4" preview-text="集团战略">
          <div class="center_text">
            <ul class="strategy_ul">

              <li v-for="(item, index) in  strategy" :key="strategy + index" class="strategy_li">{{ item }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="culture">
        <div class="product_title"> 集团文化 </div>
        <div class="culture_img">
          <div class="imgpath  imgpath1"></div>
          <div class="imgpath imgpath2"></div>
          <img src="@/assets/jyd_home/introduce/bg5.png" alt="" class="imgpath1" preview="5" preview-text="集团文化1">
          <img src="@/assets/jyd_home/introduce/bg6.png" alt="" class="imgpath2" preview="5" preview-text="集团文化2">
        </div>
        <div class="culture_card" v-for="(item, index) in  cultureList" :key="'culture' + index">
          <div class="title">{{ item.title }}</div>
          <div class="text">{{ item.text }}</div>
        </div>
        <!-- <Development /> -->
      </div>
    </div>
  </div>
</template>

<script>
// import ProductService from "./components/productService.vue";
// import structure from "./components/qualification.vue";
import Development from "./development.vue";
export default {
  components: {
    // ProductService,
    // structure,
    Development
  },
  data() {
    return {
      introduction: [
        '深圳市佳裕达物流科技有限公司成立于2009年，总部位于广东省深圳市。经过十多年的不懈努力，现已发展成为立足粤港澳大湾区、面向全国、布局全球的大型AAAA级综合服务型物流企业。服务范围涵盖空运服务、海运服务、陆运、清关、物流配送、仓储、电子商务、跨境运输、物流软件系统开发等各个领域。',
        '深圳市佳裕达物流科技有限公司以致力于发展成为 “全球领先的综合物流服务商”为愿景，将先进的物流技术和科学的营运管理融入到为客户提供安全、便捷的个性化物流服务当中，依托资源优势、资源整合能力和精益求精的科学管理方式，服务全球各行业优秀企业，有效降低企业物流成本，提高经济效益，不断为客户创造价值！',
        '2023年4月21日，集团旗下的Jayud Global Logistics Ltd（以下简称“佳裕达”），成功在美国纳斯达克IPO上市，股票代码JYD。至此，佳裕达全面开启全球化旅程！',
        ' 深圳市佳裕达物流科技有限公司与多个国内外一线知名品牌合作，涉及领域包括新能源、汽车、医疗、消费电子、母婴健康、儿童玩具、户外运动、智能穿戴等多个行业。其中与宁德时代、VIVO、OPPO、光弘集团、欣旺达、小米、联想、TCL、安克、大族激光、雅昌、中粮集团、派克、伊英克斯等数家行业头部企业保持紧密的战略合作关系。'
      ],
      strategy: ['构建集团强大的分销网络', '针对中国各垂直行业制定物流报关解决方案', '对集团的智能物流平台系统加大投入，实现智能化', '招揽行业人才，引领集团发展', '通过投资或收购拓展集团业务和服务能力，形成产业闭环']
      ,
      cultureList: [{
        title: '使命',
        text: '为客户提供安全、便捷的个性化物流服务'
      },
      {
        title: '愿景',
        text: '成为全球领先的综合物流服务商'
      },
      {
        title: '核心价值',
        text: '积极、自信、团结、创新'
      }
      ]
    }
  },
  mounted() {
  },

}
</script>

<style lang="less" scoped>
::marker {
  color: #357bff;
  font-size: calc((15/10)*1rem);
}

.introduce {
  width: 100vw;

  .product {
    width: 100vw;
    // height: calc((542/10)*1rem);

    // .product_title {
    //   width: 100%;
    //   text-align: center;
    //   font-size: calc((14/10)*1rem);
    //   font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    //   font-weight: bold;
    //   color: #333333;
    //   line-height: calc((21/10)*1rem);
    //   padding: calc((22/10)*1rem) 0 calc((17/10)*1rem) 0;
    // }

    .introduction {
      height: calc((531/10)*1rem);
      background-position: 50% 50%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 0 calc((12/10)*1rem);

      .text {
        width: 100%;
        text-align: center;
        font-size: calc((12/10)*1rem);
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: calc((18/10)*1rem);
        text-align: left;
        word-break: break-all;
        white-space: 0;
        margin-bottom: calc((17/10)*1rem);
        z-index: 2;
        position: relative;
        text-indent: 2.4rem
      }

      .introduction_Img {
        width: 100%;
        height: calc((169/10)*1rem);
      }
    }

    .structure {
      height: calc((271/10)*1rem);
      background-position: 50% 50%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 0 calc((12/10)*1rem);

      .structure_Img {
        width: 100%;
        margin-top: calc((2/10)*1rem);
        height: calc((209/10)*1rem);
      }
    }

    .strategy {
      // height: calc((342/10)*1rem);
      background-position: 50% 50%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 0 calc((12/10)*1rem);

      .strategy_center {
        width: 100%;
        height: calc((342/10)*1rem);
        background: #F8F9FC;
        border-radius: 2px;
        padding: calc((17/10)*1rem) calc((19/10)*1rem) calc((14/10)*1rem);

        .strategy_Img {
          width: 100%;
          height: calc((163/10)*1rem);
        }

        .strategy_ul {
          list-style-type: disc;
          padding-left: calc((16/10)*1rem);

          .circle {
            border: 8px solid #357bff;
            //   width: 18px;
            //   height: 18px;
            border-radius: 8px;
            background: #357bff;
          }

          .strategy_li {
            font-size: calc((12/10)*1rem);
            font-family: MicrosoftYaHei;
            color: #333333;
            line-height: calc((16/10)*1rem);
            margin-top: calc((12/10)*1rem);
            text-indent: -0.4rem
          }

          .strategy_li:hover {
            color: #357bff;
          }
        }
      }
    }

    .culture {
      height: calc((454/10)*1rem);
      background-position: 50% 50%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 0 calc((12/10)*1rem);

      .culture_img {
        display: flex;

        .imgpath {
          background-position: 50% 50%;
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .imgpath1 {
          margin-right: calc((-1/10)*1rem);
          border-radius: calc((2/10)*1rem) 0 0 calc((2/10)*1rem);
        }

        .imgpath2 {
          margin-left: calc((-1/10)*1rem);
          border-radius: 0 calc((2/10)*1rem) calc((2/10)*1rem) 0;
        }

        img,
        .imgpath {
          flex: 1;
          width: 50%;
          height: calc((122/10)*1rem);
        }
      }

      .culture_card {
        height: calc((74/10)*1rem);
        width: calc((351/10)*1rem);
        background: rgba(240, 245, 255, 0.61);
        border-radius: calc((2/10)*1rem);
        // opacity: 0.61;
        margin-top: calc((11/10)*1rem);
        display: flex;
        flex-direction: column;
        padding: calc((16/10)*1rem) 0 0 calc((29/10)*1rem);

        .title {
          font-size: calc((14/10)*1rem);
          font-weight: bold;
          line-height: calc((19/10)*1rem);
          margin-bottom: calc((8/10)*1rem);
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          color: #333333;
        }

        .text {
          font-size: calc((12/10)*1rem);
          line-height: calc((16/10)*1rem);
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          color: #333333;
          font-weight: 400;
        }
      }
    }
  }
}
</style>