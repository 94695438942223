<template>
  <div class="Header">
    <div class="top0">
      <!-- home页的显示 -->
      <div style="width: 100%;" class="Header_bg" v-show="routername == 'home'">
        <div class="swiperBg">
          <div class="swiper-container mySwiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img :src="require('@/assets/jyd_home/header/上市成功宣传图.jpg')" alt="">
              </div>
              <div class="swiper-slide" v-for="(item, index) in 7" :key="index">
                <img :src="require('@/assets/jyd_home/header/header' + item + '.png')" alt="">
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div>
      <!-- 其他页面的显示  -->
      <div class="Header_bg bghome" v-show="routername !== 'home'">
        <img :src="require('@/assets/jyd_home/header/' + routername + '.png')" alt="">
      </div>
      <img :src="require('@/assets/jyd_home/header/logo.png')" alt="" class="logo">
    </div>

    <img :src="require('@/assets/jyd_home/header/' + homeIco + '.png')" alt="" class="homeIcon" @click="homeIcoFN">
    <div class="topfixed" v-if="navOffsetTop != 0">
      <img :src="require('@/assets/jyd_home/header/logo2.png')" alt="" class="logo">
    </div>
    <el-drawer :visible.sync="drawer" direction="ttb" :before-close="handleClose">
      <template #title>
        <div class="topfixed ">
          <img :src="require('@/assets/jyd_home/header/logo2.png')" alt="" class="logo">
          <img :src="require('@/assets/jyd_home/header/close.png')" alt="" class="close" @click="homeIcoFN">
        </div>
      </template>
      <div class="drawerclass">

        <div :class="item.path == routername ? 'headersty headerstyActive' : 'headersty'"
          v-for="(item, index) in HeaderList" :key="'headersty' + index" @click="headerClick(item)">
          <span>
            {{ item.title }}
          </span>
        </div>

      </div>

    </el-drawer>

  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
export default {
  data() {
    return {
      // 当前路由名称
      routername: '',
      // logo是那种图
      logo: 'logo',
      // 走马灯距离顶部的距离 
      navOffsetTop: 0,
      // 当前 icon的图片是那个
      homeIco: 'home',
      // drawer  抽屉
      drawer: false,
      // 菜单列表
      HeaderList: [{
        title: '首页',
        path: 'home'
      },
      {
        title: '物流科技',
        path: 'introduce'
      }
        , {
        title: '产品服务',
        path: 'chanpinfuwu'
      }, {
        title: '智能科技',
        path: 'Intelligence'
      }, {
        title: '人才招聘',
        path: 'Recruitment'
      }, {
        title: '投资者关系',
        link: 'https://ir.jayud.com/'
      }]
    }
  },
  created() {

    this.routername = this.$router.history.current.name ? this.$router.history.current.name : 'home'


  },
  mounted() {
    this.SwiperFN();
    // 监听滚动事件
    window.addEventListener('scroll', this.fiexdNav)
    this.getData()
  },
  destroyed() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener('scroll', this.fiexdNav)
  },
  watch: {
    routername(value) {
      if (value !== 'home') return
      this.SwiperFN()

    }
  },
  methods: {
    handleClose() {
      this.homeIcoFN()
    },
    headerClick(item) {
      item.path ? this.$router.push('/' + item.path) :
                  window.open(item.link)
      this.homeIcoFN() 
      this.routername = item.path ||this.routername
    },
    // Swiper的初始化函数 
    SwiperFN() {
      if (this.routername == 'home') {
        new Swiper(".mySwiper", {
          // 如果需要前进后退按钮
          observer: true, // 修改swiper自己或子元素时，自动初始化swiper
          observeParents: true,
          rewind: true,
          loop: true,
          centeredSlides: true,
          autoplay: {
            delay: 3000,//自动切换时间；
            //与loop有关，设置为true，loop失效
            stopOnLastSlide: false,
            //用户操作之后，是否禁止自动切换（默认true禁止/设置false允许）
            disableOnInteraction: true,
          },
          // spaceBetween: 10,
          spaceBetween: 0,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
            bulletClass: 'my-pagination',
            bulletActiveClass: 'my-pagination-active',
          },
          effect: "coverflow",

          coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 0,
            modifier: 0,
            slideShadows: false,
          },
        });
      }
    },
    // 获取当前的头部的高度
    getData() {
      this.navOffsetTop = document.querySelector('.top0').offsetTop
    },
    // 高度监听
    fiexdNav() {
      // 2.获取当前页面的卷曲高度
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // const nav = document.querySelector('.top0')
      // console.log(scrollTop, nav);
      this.navOffsetTop = scrollTop
      this.homeIco = scrollTop == 0 ? 'home' : 'home2'
    },
    homeIcoFN() {
      this.drawer = !this.drawer
    }
  },
}
</script> 

<style lang="less" scoped>
.Header {
  width: 100vw;
  position: relative;

  .top0 {
    width: 100vw;
    position: relative;
  }

  .Header_bg {
    width: 100%;
    height: calc((248/10)*1rem);

    img {
      width: 100%;
      height: calc((248/10)*1rem);
    }

    .swiperBg {
      width: 100%;
      height: 100%;

      .swiper-container {
        width: 100%;
        height: 100%;

        .swiper-slide {
          height: 100%;
          width: 100%;
          // background: #FFFFFF;
          box-shadow: 0px 2px 12px 0px rgba(207, 207, 207, 0.5);
          border-radius: 2px;
          text-align: center;
          font-size: 18px;
          background: #fff;


        }

      }
    }


  }

  .bghome {
    height: calc((210/10)*1rem);

    img {
      width: 100%;
      height: calc((210/10)*1rem);
    }
  }

  .logo {
    position: absolute;
    width: calc((136/10)*1rem);
    height: calc((28/10)*1rem);
    top: calc((12/10)*1rem);
    left: calc((15/10)*1rem);
    z-index: 200;
  }

  .homeIcon,
  .close {
    position: fixed;
    height: calc((18/10)*1rem);
    width: calc((23/10)*1rem);
    top: calc((16/10)*1rem);
    right: calc((15/10)*1rem);
    z-index: 202;
  }

  .close {
    height: calc((20/10)*1rem);
    width: calc((21/10)*1rem);
  }

  .topfixed {
    position: fixed;
    width: 100vw;
    height: calc((52/10)*1rem);
    top: 0;
    left: 0;
    z-index: 201;
    background-color: #fff;
    box-shadow: 0px 2px 7px 0px rgba(235, 235, 235, 0.5);
  }

  ::v-deep // background-color: rgba(254, 254, 254, 0);

  .el-drawer__wrapper {

    // height: calc((326/10)*1rem);
    .el-drawer {
      height: calc((375/10)*1rem) !important;
      box-shadow: 0px 2px 7px 0px rgba(218, 218, 218, 0.5);
    }

    .el-drawer__header {
      padding: 0;
      height: calc((52/10)*1rem);
      margin: 0;
    }

    .el-drawer__body {
      height: calc((275/10)*1rem);
    }


  }





  .drawerclass {
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 0 calc((12/10)*1rem) 0;

    .headersty {
      width: 100%;
      height: calc((49/10)*1rem);
      border-bottom: calc((1/10)*1rem) solid #E0E0E1;

      margin-bottom: calc((3/10)*1rem);
      padding-top: calc((20/10)*1rem);

      span {
        font-size: calc((14/10)*1rem);
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #333333;
        line-height: calc((19/10)*1rem);

      }

      &:active,
      &:focus {
        span {
          color: #357BFF;

        }
      }
    }

    .headerstyActive {

      span {
        color: #357BFF;
      }
    }
  }
}
</style>