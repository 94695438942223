<template>
  <div class="Footer">
    <div class="foot_top">
      <div class="foot_top_l">
        <img class="logo" src="@/assets/jyd_home/footer/footLogo.png" preview="11" preview-text="logo" />
        <div class="text1">全国统一服务热线：</div>
        <div class="text2">0755-2536 9609</div>
      </div>
      <div class="foot_top_m">
        <p class="text3">微信公众号</p>
        <img class="gzh" src="@/assets/jyd_home/footer/gzh.png" preview="10" preview-text="微信公众号" />
      </div>
      <div class="foot_top_r">
        <div class="anniu_top">
          <div class="foot_top_weixin">
            <img class="wx" src="@/assets/jyd_home/footer/weixin.png" />
          </div>
          <div class="foot_top_hezuo" style="margin-left: 1.67rem">
            <img class="hz" src="@/assets/jyd_home/footer/hezuo.png" />
          </div>
        </div>
        <div class="anniu_bottom">
          <img class="kf" src="@/assets/jyd_home/footer/kefu.png" />
          <p class="text4">在线客服</p>
        </div>
      </div>
    </div>
    <div class="foot_bottom">

      <span>© 2023 深圳市佳裕达物流科技有限公司 版权所有</span>
      <span><a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff">粤ICP备2024315856号</a></span>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: 1,
    };
  },

  mounted() {
    // (function (doc, win) {
    //   let docEl = doc.documentElement;
    //   let resizeEvt = 'orientationcyunhange' in window ? 'orientationchange' : 'resize';
    //   let recalc = function () {
    //     // 获取当前设备的窗口宽度 
    //     var clientWidth = docEl.clientWidth;
    //     if (!clientWidth) return;
    //     // 动态设置全局字体大小 
    //     docEl.style.fontSize = 10 * (clientWidth / 375) + 'px';
    //     console.log(docEl.style.fontSize);
    //   };
    //   if (!doc.addEventListener) return;
    //   // 绑定事件 
    //   win.addEventListener(resizeEvt, recalc, false);
    //   doc.addEventListener('DOMContentLoaded', recalc, false);
    // })(document, window);
  },
};
</script> 

<style lang="less" scoped>
.Footer {
  width: 100vw;
  background: #252838;
  padding: calc((12/10)*1rem) calc((18/10)*1rem) calc((13/10)*1rem);

  .foot_top {
    display: flex;

    // position: relative;

    .foot_top_l {
      width: calc((130/10)*1rem);

      .logo {
        width: calc((47/10)*1rem);
        height: calc((29/10)*1rem);
        margin-bottom: calc((12/10)*1rem);
      }

      .text1 {
        color: #ffffff;
        margin-bottom: calc((3/10)*1rem);
        font-size: 1rem;
        font-family: MicrosoftYaHei;
        color: #FFFFFF;
        line-height: calc((14/10)*1rem);
      }

      .text2 {
        color: #ffffff;
        font-size: 1.7rem;
        font-family: 'Akrobat-ExtraBold';
        font-weight: 800;
        color: #FFFFFF;
        width: 13rem;
        line-height: calc((21/10)*1rem);
        letter-spacing: calc((1/10)*1rem);
        display: flex;
        flex-wrap: nowrap;
      }
    }

    .foot_top_m {
      color: #ffffff;
      padding-left: calc((12/10)*1rem);
      width: calc((83/10)*1rem);

      .text3 {
        color: #ffffff;
        font-size: 0.83rem;
        width: calc((50/10)*1rem);
        margin-bottom: calc((7/10)*1rem);
        font-size: 1rem;
        font-family: MicrosoftYaHei;
        color: #FFFFFF;
        line-height: calc((14/10)*1rem);
      }

      .gzh {
        width: calc((46/10)*1rem);
        height: calc((46/10)*1rem);
        margin-left: calc((2/10)*1rem);
      }
    }

    .foot_top_r {
      margin: calc((6/10)*1rem) 0 0 0;
      // position: absolute;
      // top: 0;
      // right: 0;
      padding-left: calc((71/10)*1rem);
      width: calc((151/10)*1rem);

      .anniu_top {
        display: flex;

        .foot_top_weixin,
        .foot_top_hezuo {
          width: calc((30/10)*1rem);
          height: calc((30/10)*1rem);
          background: #357bff;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          .wx,
          .hz {
            width: calc((19/10)*1rem);
            height: calc((16/10)*1rem);
          }
        }
      }

      .anniu_bottom {
        display: flex;
        align-items: center;
        border-radius: calc((14/10)*1rem);
        background: #357bff;
        height: calc((28/10)*1rem);
        margin-top: 1rem;
        width: calc((80/10)*1rem);
        padding: 0 calc((9/10)*1rem);

        .kf {
          width: calc((18/10)*1rem);
          height: calc((15/10)*1rem);
          margin: 0 calc((2/10)*1rem) 0 calc((0/10)*1rem);
        }

        .text4 {
          font-size: 1rem;
          font-family: MicrosoftYaHei;
          color: #FFFFFF;
          line-height: calc((14/10)*1rem);
          color: #ffffff;
          margin-right: calc((1/10)*1rem);

        }
      }
    }
  }

  .foot_bottom {
    width: 100%;
    margin-top: calc((4/10)*1rem);
    color: #FFFFFF;


    span {
      font-size: 1rem;
      font-family: MicrosoftYaHei;
      color: #FFFFFF;
      line-height: calc((14/10)*1rem);
      margin-right: 1rem;
    }
  }
}
</style>