<template>
    <div class="personnelManager">
        <div class="tit">
            <div class="tit_heart">
                <span class="tit_Text">人才招聘</span><i class="el-icon-arrow-right"></i>
                <span class="tit_Icon" @click="back()">社会招聘</span><i class="el-icon-arrow-right"></i><span class="tit_Icon">职位详情</span>
            </div>
        </div>
        <div class="con">
            <div class="applyForPosition">
                <p class="position_left">职位名称：海运/铁路操作</p>
                <el-button type="primary" disabled>申请职位</el-button>
            </div>
        </div>
        <div class="introduce">
            <div class="introduce_top">
                <!-- <p class="sty" style="margin-right:383px">招聘人数：1人</p> -->
                <!-- <p class="sty" style="margin-right:335px">招聘有效期：2月～5月</p> -->
                <p class="sty">薪酬范围：7000-16000</p>
            </div>
            <div class="introduce_bottom" style="margin-top:16px">
                <p class="sty" style="margin-right:360px">学历要求：大专学历</p>
                <p class="sty">经验要求：2年以上经验</p>
            </div>
        </div>
        <div class="operatingDuty">
            <div class="operatingDuty_text">工作职责</div>
            <ul class="operatingDuty_item">
                <li>1、负责操作部门一条龙全航线操作+客服流程，包括并不限于：订舱、安排提货、拖柜、装箱、报关、制作提单、核对并录入费用、发账单给客户并追踪应收账款、文件存档等等；</li>
                <li>2、及时准备相关的货运单证、海关文件等，确保顺利清关；</li>
                <li>3、积极配合客户解决遇到的问题；</li>
                <li>4、处理客户纠纷及投诉，并及时向上级汇报；</li>
                <li>5、其他和本岗位相关的未尽事项；</li>
                <li>6、熟悉船司订舱流程；</li>
                <li>7、熟悉全国各口岸订舱，出运要求。</li>
            </ul>
        </div>
        <div class="operatingDuty" style="padding:43px 0 54px;">
            <div class="operatingDuty_text">岗位要求</div>
            <ul class="operatingDuty_item">
                <li>1、国际贸易、国际物流管理、报关与国际货运等相关专业大专及以上学历，英语良好；</li>
                <li>2、两年以上国际货代作经验，有灵敏的市场分析及判断能力；</li>
                <li>3、精通国际空运/海运/铁路进出口的业务流程；</li>
                <li>4、吃苦耐劳、勤奋好学，有强烈进取心；</li>
                <li>5、高度的责任感和较强的工作责任心，沟通协调能力；</li>
                <li>6、良好的团队合作精神，分析、沟通和协调能力,能合理调配自己的时间。</li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default{
        data(){
            return{
                bacKShow:null
            }
        },
        methods:{
            back(){
                this.bacKShow = 1
                this.$emit('bacKShow',this.bacKShow)
            }
        }
    }
</script>

<style lang="less" scoped>
.personnelManager{
    .tit{   
        background: #F4F3F3;
        height: 60px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 60px;
        .tit_heart{
            margin: 0 auto;
            width: 1200px;
            .tit_Text{
                margin-right: 11px;
            }
            .tit_Icon{
                margin: 11px;
            }
        }
    }
    .con{
        margin: 0 auto;
        width: 1200px;
        padding-top: 38px;
        .applyForPosition{
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .position_left{
                font-size: 18px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #333333;
                line-height: 24px;
            }
            /deep/.el-button--primary.is-disabled{
                background: #357BFF;
                border: #357BFF;
                border-radius: 2px;
            }
        }
    }
    .introduce{
        padding: 39px 0 37px;
        width: 1200px;
        margin: 0 auto;
        border-bottom: 1px solid #CCCCCC;
        .introduce_top,.introduce_bottom{
            display: flex;
            .sty{
                font-size: 16px;
                font-family: MicrosoftYaHei;
                color: #333333;
                line-height: 21px;
            }
        }
    }
    .operatingDuty{
        width: 1200px;
        margin: 0 auto;
        padding-top: 36px;
        .operatingDuty_text{
            font-size: 18px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #333333;
            line-height: 24px;
        }
        .operatingDuty_item{
            padding: 29px 0 0 72px;
            li{
                font-size: 16px;
                font-family: MicrosoftYaHei;
                color: #333333;
                line-height: 21px;
                margin-bottom: 12px;
            }
        }
    }
}
</style>